/* eslint class-methods-use-this: 0 */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import AddressApi from './address-api-resource.service';
import AgencyApi from './agency-api-resource.service';
import DepartmentApi from './department-api-resource.service';
import EmailApi from './email-api-resource.service';
import HealthApi from './health-api.service';
import InvoiceApi from './invoice-api-resource.service';
import NewsletterApi from './newsletter-api-resource.service';
import OfferApi from './offer-api-resource.service';
import PaymentApi from './payment-api-resource.service';
import ShipmentApi from './shipment-api-resource.service';
import RequestsApi from './request-api-resource.service';
import InternationalCountriesApi from './internationalCountries-api-resource.service';
import StripeApi from './stripe-api-resource.service';
import OneWayApi from './oneway-api-resource.service';
import VatApi from './vat-api-resource.service';

export default class ColisportApi {
  private static instance: ColisportApi;

  readonly API_SERVER_URL = '/api';

  readonly CONFIG: AxiosRequestConfig = {
    baseURL: this.API_SERVER_URL,
  };

  private readonly httpClient: AxiosInstance = axios.create(this.CONFIG);

  // API resources

  readonly addressApi: AddressApi;

  readonly emailApi: EmailApi;

  readonly offerApi: OfferApi;

  readonly departmentApi: DepartmentApi;

  readonly shipmentApi: ShipmentApi;

  readonly newsletterApi: NewsletterApi;

  readonly paymentApi: PaymentApi;

  readonly invoiceApi: InvoiceApi;

  readonly agencyApi: AgencyApi;

  readonly healthApi: HealthApi;

  readonly requestsApi: RequestsApi;

  readonly internationalCountriesApi: InternationalCountriesApi;

  readonly stripeApi: StripeApi;

  readonly oneWayApi: OneWayApi;

  readonly vatApi: VatApi;

  private constructor() {
    this.shipmentApi = new ShipmentApi(this.httpClient);
    this.addressApi = new AddressApi(this.httpClient);
    this.offerApi = new OfferApi(this.httpClient);
    this.emailApi = new EmailApi(this.httpClient);
    this.departmentApi = new DepartmentApi(this.httpClient);
    this.newsletterApi = new NewsletterApi(this.httpClient);
    this.paymentApi = new PaymentApi(this.httpClient);
    this.invoiceApi = new InvoiceApi(this.httpClient);
    this.agencyApi = new AgencyApi(this.httpClient);
    this.healthApi = new HealthApi(this.httpClient);
    this.requestsApi = new RequestsApi(this.httpClient);
    this.internationalCountriesApi = new InternationalCountriesApi(this.httpClient);
    this.stripeApi = new StripeApi(this.httpClient);
    this.oneWayApi = new OneWayApi(this.httpClient);
    this.vatApi = new VatApi(this.httpClient);
  }

  static getInstance(): ColisportApi {
    if (!ColisportApi.instance) {
      ColisportApi.instance = new ColisportApi();
    }
    return ColisportApi.instance;
  }
}
