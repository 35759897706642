import { AxiosInstance } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';

export default class VatApi implements ColisportApiResource {
  readonly apiUrlPath = 'vat';

  constructor(readonly httpClient: AxiosInstance) {}

  async getVadidation(countryCode: string, vatNumber: string) {
    return this.httpClient.get(
      `${this.apiUrlPath}/validate?countryCode=${countryCode}&vatNumber=${vatNumber}`,
    );
  }
}
